var AppiMembers = (function() {
    "use strict";

    // Set defaults here
    var options = {

    };

    // Public properties
	var pub = {},
        dtTable = null,         // The datatables table.
        dtTableId = null,       // The html id of the members table.
        mef_validator = null,   // The validator beeing used in the members' create/edit form.
        ef_validator = null;    // The validator for the email form.

    //Private properties
    var timerId = null;

    ///////////////////////////////////////////////////////////////////////////////
    // Public methods
    ///////////////////////////////////////////////////////////////////////////////

	/**
	 * Callback of an "upload" button to import a Member Application form
	 * sent by emnail. It is supposed to be called from a Bootstrap modal.
	 *
	 * The files are expected to be in EML. This format was choosen because
	 * EML files are text files that can be opened using most e-mail clients,
	 * such as Microsoft Outlook, Mozilla Thunderbird, Apple Mail, or
	 * IncrediMail.
	 *
	 * @param {event} loadEvt - The event.
	 */
	pub.ImportApplicationForm = function(loadEvt) {
        var file = $(this)[0].files[0];

        // Eml files only.
        if (!file.name.match('^.+\.eml$')) {
            bootbox.dialog({
                    message: "Só são admitidos ficheiros EML.",
                    title:   "Ficheiro Inválido",
                    icon:    "fa-exclamation text-danger",
                    buttons: { OK: { } }
            });
            return;
        }

        var reader = new FileReader();

        /**
         * The callback associated to the reader.
         *
         * @param  {e} Event.

         * @todo - Lots of margin for improvement here.
         */
        reader.onload = function(e) {
            var text = e.target.result;
            var address = '';
            var array;
            var zip;
            var form = loadEvt.target.closest('form');
            var modal = loadEvt.target.closest('.modal');

            var form_data = AppiUtils.parseMemberApplicationFormEML(text);

            // Erase all input fields in the form
            $(form)[0].reset();
            mef_validator.resetForm();

            // Update title to reflect import and show file name.
            AppiUtils.SetModalTitle(modal, 'Importar Associado - ' + file.name);

            // Check if this is a membership form
            if (form_data.type == 'UNKNOWN') {
                bootbox.dialog({
                        message: "Esta mensagem não parece conter uma proposta de associado.",
                        title:   "Ficheiro Inválido",
                        icon:    "fa-exclamation text-danger",
                        buttons: { OK: { } }
                });
                return;
            }

            AppiUtils.ajaxCheckZipCode(form_data);

            $('#last_name').val(form_data['last_name']);
            $('#full_name').val(form_data['full_name']);
            $('#birth_date').datepicker('setDate', form_data['birth_date']);
            $('#nif').val(form_data['nif']);
            $('#admission_year').val(form_data['admission_year']);
            $('#country').val(form_data['country']);
            $('#address').val(form_data['address']);
            $('#address').prop('title', 'Enviado: \n' + form_data['raw_address']);
            $('#zip_code').val(form_data['zip']);
            $('#place').val(form_data['place']);
            if (form_data['phone']) {
                if (form_data['phone'].substr(0, 1) === "2") {
                    $('#fixed_phone').val(form_data['phone']);
                }
                else if (form_data['phone'].substr(0, 1) === "9") {
                    $('#mobile_phone').val(form_data['phone']);
                }
                else {
                    $('#other_phone').val(form_data['phone']);
                }
            }
            $('#email').val(form_data['email']);
            $('#edu_institution').val(form_data['edu_institution']);
            $('#edu_level').val(form_data['edu_level']);

            // These aren't read but default to true here.
            $('input:radio[name=newsletter]').filter('[value=1]').prop('checked', true);
            $('input:radio[name=active]').filter('[value=1]').prop('checked', true);

            // Not read but default to false.
            $('input:radio[name=institution]').filter('[value=0]').prop('checked', true);

            // Force form validation
            mef_validator.form();
        };
        reader.readAsText(file);
    }

    /**
     * Runs before the member's edit/create modal is shown.
     *
     * @param event The event that triggered the modal.
     * @returns Nothing.
     */
    pub.OnShowEditModal = function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var action = button.data('action'); // Extract info from data-* attributes
        var modal = $(this);

        $(this).find('.spinner').hide();

        // Clean any existing validation messages ...
        mef_validator.resetForm();
        // ... and address title used during imports
        $('#address').prop('title', '');

        if (action == "edit") {
            // We're editing so hide application form import
            $('.custom-file-import').hide();

            // Get row data
            var aData = dtTable.row('.selected').data();

            // Check if there is a row selected (assumes datatables is configured to have only one selected row)
            if (null != aData) {
                AppiUtils.SetModalTitle(modal, 'Editar Associado');

                $('#id').val(aData['id']);
                $('#number').val(aData['number']).closest('.form-group').show();
                $('#full_name').val(aData['full_name']);
                $('#last_name').val(aData['last_name']);
                $('#birth_date').datepicker('setDate', AppiUtils.toDate(aData['birth_date']));
                $('#address').val(aData['address']);
                $('#zip_code').val(aData['zip_code']);
                $('#place').val(aData['place']);
                $('#district').val(aData['district']);
                $('#country').val(aData['country']);
                $('#fixed_phone').val(aData['fixed_phone']);
                $('#mobile_phone').val(aData['mobile_phone']);
                $('#other_phone').val(aData['other_phone']);
                $('#edu_institution').val(aData['edu_institution']);
                $('#edu_level').val(aData['edu_level']);
                $('#nif').val(aData['nif']);
                $('#ncc').val(aData['ncc']);
                if (parseInt(aData['newsletter'], 10) == 1) {
                    $('input:radio[name=newsletter]').filter('[value=1]').prop('checked', true);
                }
                else {
                    $('input:radio[name=newsletter]').filter('[value=0]').prop('checked', true);
                }
                if ($('#admission_year').val() != "0")
                    $('#admission_year').val(aData['admission_year']);
                else
                    $('#admission_year').val('');
                $('#email').val(aData['email']);
                if (parseInt(aData['active'], 10) == 1) {
                    $('input:radio[name=active]').filter('[value=1]').prop('checked', true);
                }
                else {
                    $('input:radio[name=active]').filter('[value=0]').prop('checked', true);
                }

                if (parseInt(aData['institution'], 10) == 1) {
                    $('input:radio[name=institution]').filter('[value=1]').prop('checked', true);
                }
                else {
                    $('input:radio[name=institution]').filter('[value=0]').prop('checked', true);
                }

                $('#observations').val(aData['observations']);

                // Prepare for submit
                modal.data( "action", 'edit' );
            }
            else {
                // There was nothing selected
                return event.preventDefault();
            }
        }
        else if (action == "create") {
            $('.custom-file-import').show();
            AppiUtils.SetModalTitle(modal, 'Criar Associado');

            $('#id').val('');
            $('#number').val('').closest('.form-group').hide();
            $('#full_name').val('');
            $('#last_name').val('');
            $('#birth_date').datepicker('setDate', null);
            $('#address').val('');
            $('#zip_code').val('');
            $('#place').val('');
            $('#country').val('Portugal');
            $('#district').val('');
            $('#fixed_phone').val('');
            $('#mobile_phone').val('');
            $('#other_phone').val('');
            $('#edu_institution').val('');
            $('#edu_level').val('');
            $('#nif').val('');
            $('#ncc').val('');
            $('input:radio[name=newsletter]').filter('[value=1]').prop('checked', true);
            $('input:radio[name=active]').filter('[value=1]').prop('checked', true);
            $('#admission_year').val(new Date().getFullYear()); //.closest('.form-group').hide();
            $('#email').val('');
            $('input:radio[name=institution]').filter('[value=0]').prop('checked', true);
            $('#observations').val('');

            // Prepare for submit
            modal.data( "action", 'create' );
        }
    }

    /**
     * Runs before the member's delete modal is shown.
     *
     * @param event The event that triggered the modal.
     */
    pub.OnShowDeleteModal = function (event) {
        var aData = dtTable.row('.selected').data();

        $(this).find('.spinner').hide();

        // Delete any existing text on modal
        $(this).find('.modal-body').find('p').remove();
        $(this).find('.modal-body').find('h4').remove();

        if (aData != null)
        {
            $('#id').val(aData['id']);
            $(this).find('.modal-body').prepend('<p class="text-danger"><b>Todos os pagamentos registados para este associado serão também eliminados!</b></p>');
            $(this).find('.modal-body').prepend('<p><b>Nome: </b> <span id="delete_name"> </span></p>');
            $(this).find('.modal-body').prepend('<p><b>Número: </b> <span id="delete_number"> </span></p>');
            $(this).find('.modal-body').prepend('<h4><b>Confirma eliminação do associado:</b></h4>');
            $(this).find('#delete_number').text(aData['number'] ? aData['number'] : '-');
            $(this).find('#delete_name').text(aData['full_name'] ? aData['full_name'] : '-');
        }
    }

    /**
     * Runs before the member's email modal is shown.
     *
     * @param event The event that triggered the modal.
     */
    pub.OnShowEmailModal = function (event) {
        var aData;
        var email_addresses = [];

        $(this).find('.spinner').hide();

        ef_validator.resetForm()

        // Reset all fields
        $(event.target).find('form')[0].reset();
        $('#email_payables').selectpicker('deselectAll');

        if (dtTable.row('.selected').data()) {
            // Use the selected rows
            aData = dtTable.rows('.selected').data();
        }
        else {
            // Use all rows! Dangerous!
            aData = dtTable.rows( {search:'applied'} ).data();
        }

        for (var i = 0; i < aData.length; i++) {
            if (aData[i]['email']) {
                email_addresses.push(aData[i]['email']);
            }
        }

        if (email_addresses.length == 0) {
             AppiUtils.SetModalTitle(this, 'Não existem emails válidos na lista');
            $(this).find(':submit').prop('disabled', true);
        }
        else {
            if (email_addresses.length == 1)
                AppiUtils.SetModalTitle(this, "Existe 1 email válido na lista");
            else
                AppiUtils.SetModalTitle(this, "Existem " + email_addresses.length + " emails válidos na lista");
            $(this).find(':submit').prop('disabled', false);
        }
    }


    /**
     * Runs before the member's properties modal is shown.
     *
     * @param event The event that triggered the modal.
     */
    pub.OnShowMemberPropertiesModal = function (event) {
        var aData = dtTable.row('.selected').data();
        var modalSpinner = $(this).find('.spinner');

        modalSpinner.show();

        // Delete any existing list-group-items on modal
        $(this).find('.modal-body').find('.list-group-item').remove();

        AppiUtils.SetModalTitle(this, "Registos de Pagamento do Associado " + aData['number']);

        $.ajax({
            type: "GET",
            url: "/memberpayments/" + aData['id'],
            success: function(data) {
                        var i;
                        for (i = 0; i < data.length; i++) {
                            if (data[i].payable_type == 1)
                                $('#memberPropertiesModal').find('.panel-fees .list-group').append('<li class="list-group-item">'+data[i].name+'</li>');
                            else
                                $('#memberPropertiesModal').find('.panel-events .list-group').append('<li class="list-group-item">'+data[i].name+'</li>');
                        }
                        modalSpinner.hide();
                     },
            error: function(xhr, err, status) {
                        modalSpinner.hide();
                        bootbox.dialog({
                                message: "Erro a obter pagamentos do associado.",
                                title:   "Erro no acesso à BD",
                                icon:    "fa-exclamation text-danger",
                                buttons: { OK: { } }
                        });
                    }
        });
    }

    /**
     * The submit handler for the member's edit/create modal.
     *
     * @param event The event that triggered the modal.
     */
    pub.editFormSubmitHandler = function(event) {
        var modal = $(this).closest('.modal');
        var form = $(this).closest('form');
        var action = modal.data('action');

        modal.find('.spinner').show();

        $.ajax({
            type: "POST",
            url: action == "edit" ? "/members/" + $('#id').val() : "/members",
            data: form.serialize().replace(/\++/g, '+'),
            success: function(data, textStatus, xhr) {
                        var result = jQuery.parseJSON(xhr.responseText);
                        AppiUtils.showAlert(result.error_msg, result.success);
                        if (dtTable) {
                            dtTable.ajax.reload();
                            dtTable.row().invalidate().draw();
                        }
                        modal.find('.spinner').hide();
                        modal.modal('hide');
                     },
            error: function(xhr, err, status) {
                        var error = jQuery.parseJSON(xhr.responseText);

                        modal.find('.spinner').hide();
                        for (var k in error) {
                            if(error.hasOwnProperty(k) && Array.isArray(error[k])) {
                                var form_group = $('#'+k).closest('.form-group');
                                var block = form_group.find('.help-block').not('#'+k+'-error');
                                form_group.addClass('has-error');
                                block.addClass('with-errors');
                                block.find(".list-unstyled").remove();
                                var list = block.append('<ul class="list-unstyled"></ul>').find('ul');
                                error[k].forEach(function(val) {
                                    list.append('<li>' + val + '</li>');
                                });
                            }
                        }
                    }
        });
        event.preventDefault();
    }

    /**
     * The submit handler for the member's delete modal.
     *
     * @param event The event that triggered the modal.
     */
    pub.deleteFormSubmitHandler = function(event) {
        var modal = $(this).closest('.modal');
        var form = $(this).closest('form');
        var aData = dtTable.row('.selected').data();

        modal.find('.spinner').show();

        $.ajax({
            type: "POST",
            url: "/members/" + $('#id').val(),
            data: form.serialize(),
            success: function(data, textStatus, xhr) {
                        dtTable.ajax.reload();
                        dtTable.row().invalidate().draw();
                        $("#delete_btn").closest("form").attr("action", "/members/" + 0);
                        $('#edit_btn, #delete_btn, #properties_btn').toggleClass('disabled', true).disabled = true;
                        modal.modal('hide');
                     },
            error: function(xhr, err, status) {
                        var error = jQuery.parseJSON(xhr.responseText);
                    },
            complete: function(xhr, status) {
                        var result = jQuery.parseJSON(xhr.responseText);

                        modal.find('.spinner').hide();
                        AppiUtils.showAlert(result.error_msg, result.success);
            }
        });
        event.preventDefault();
    }

    /**
     * The submit handler for the member's email modal.
     *
     * @param event The event that triggered the modal.
     */
    pub.emailFormSubmitHandler = function(event) {
        var modal = $(this).closest('.modal');
        var form = $(this).closest('form');
        var formData = new FormData(form[0]);
        var aData;
        var numberValidEmails = 0;

        event.preventDefault();

        if (ef_validator.form()) {
            if (dtTable.row('.selected').data()) {
                // Use the selected rows
                aData = dtTable.rows('.selected').data();
            }
            else {
                // Use all rows!
                aData = dtTable.rows( {search:'applied'} ).data();
            }

            // Iterate through all email addresses and append to formData
            for (var i = 0; i < aData.length; i++) {
                if (aData[i]['email']) { // TODO - Should try to validate this. Regex !?
                    numberValidEmails += 1;
                    formData.append('ids[]', aData[i]['id']);
                }
            }

            // The form validated successfully
            bootbox.dialog({
                    message: "" +
                            "A mensagem que definiu será enviada para <b>" +
                            numberValidEmails + "</b> " +
                            (numberValidEmails == 1 ? "associado." : "associados.") +
                            "<br><br>Confirma o envio?" +
                            "",
                    title:   "Confirma envio?",
                    icon:    "fa-question-circle text-danger",
                    buttons: {
                        send: {
                            label: "Enviar",
                            className: "btn-primary",
                            callback: function() {
                                modal.find('.spinner').show();
                                // Invoke mailer using Ajax
                                $.ajax({
                                    type: "POST",
                                    url: "/mailer",
                                    data: formData,
                                    processData: false,
                                    contentType: false,
                                    success: function(data, textStatus, xhr) {
                                                modal.find('.spinner').hide();
                                                modal.modal('hide');
                                            },
                                    error: function(xhr, err, status) {
                                                var error = jQuery.parseJSON(xhr.responseText);
                                            },
                                    complete: function(xhr, status) {
                                                var result = jQuery.parseJSON(xhr.responseText);
                                                AppiUtils.showAlert(result.error_msg, result.success);
                                    }
                                });
                            }
                        },
                        cancel: {
                            label: "Cancelar",
                            className: "btn-default",
                            callback: function() {
                                ;
                            }
                        }
                    }
            });
        }
    }

    /**
     * Build a PDF with address labels.
     * Each containing the address of a selected Member.
     *
     * @param e Event object that triggered the event.
     * @param dt A DataTables API instance for the host DataTable.
     * @param node jQuery instance for the button node that was clicked on.
     * @param config The button's configuration object.
     */
    function BuildLabelsPdf( e, dt, node, config ) {
        var VSTEP = 120.27, HSTEP = 198.43, HSTART = 0, VSTART = 0;
        var LABELS_PER_ROW = 3, ROWS_PER_PAGE = 7, LABELS_PER_PAGE = LABELS_PER_ROW * ROWS_PER_PAGE;
        var ct = [];
        var row = [];
        var label = {};
        var docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
            pageMargins: [ 0, 0, 0, 0 ],
            content: ct,
            defaultStyle: {
                columnGap: 0,
            }
        };
        var i;
        var selObj = {};

        /*
         * If there are any selected rows, we'll use them,
         * otherwise we'll use every row in the table.
         */
        if (dt.rows( {selected: true} ).count() == 0) {
            selObj = {order: 'current', search: 'applied'};
        }
        else {
            selObj = {order: 'current', search: 'applied', selected: true};
        }

        var nrRows = dt.rows(selObj).count();

        bootbox.dialog({
                message: "Vão ser geradas etiquetas para <b>" + nrRows + "</b> " +
                         "associados.<br>" +
                         (nrRows > 500 ? "Esta operação pode ser bastante demorada.<br><br>" : "<br>") +
                         "Quer continuar?",
                title:   "Confirma Operação?",
                icon:    "fa-question-circle text-danger",
                buttons: {
                    confirm: {
                        label: "Confirmar",
                        className: "btn-primary",
                        callback: function() {
                            $('#spinner').show();
                            dt.rows(selObj).every( function (rowIdx, tableLoop, rowLoop) {
                                var member = this.data();
                                var i = rowLoop + 1;
                                var curRowX = HSTART,
                                    curRowY = VSTART + Math.floor((i-1) % LABELS_PER_PAGE / LABELS_PER_ROW) * VSTEP;

                                // Build the label, and push it into the current row
                                label = {
                                            width: HSTEP,
                                            fontSize: 9,
                                            margin: [10, 30, 10, 30],
                                            text: member['number'] + '\n' +
                                                  member['full_name'] + '\n' +
                                                    '\n' +
                                                    member['address'] + '\n' +
                                                    member['zip_code'] + ' ' + member['place']
                                        };
                                row.push(label);

                                // If we're done with the current row, push it into the content
                                // and prepare for next one.
                                if (i % LABELS_PER_ROW == 0 || i == nrRows) {
                                    if (i % LABELS_PER_PAGE || i == nrRows) {
                                        ct.push({absolutePosition : { x: curRowX, y: curRowY }, columns: row});
                                    }
                                    else {
                                        ct.push({pageBreak: 'after', absolutePosition : { x: curRowX, y: curRowY }, columns: row});
                                    }
                                    row = [];
                                }
                            } );
                            $('#spinner').hide();

                            pdfMake.createPdf(docDefinition).open();
                        }
                    },
                    cancel: {
                        label: "Cancelar",
                        className: "btn-default"
                    }
                }
        });
    }

    /**
     * Associates the specified table id with a datatables table.
     *
     * @param {string} id - The table id.
     */
    pub.AttachDatatable = function(id) {
    	dtTableId = id;

        // Default opacity to 50% when nothing is loaded.
        $(dtTableId).css({ opacity: 0.5 });

        // Define callback to import EML files.
        $('#member_application_file').change(this.ImportApplicationForm);

        // Init form validators.
        mef_validator = AppiUtils.initFormValidation('edit_member_form');
        ef_validator =  AppiUtils.initFormValidation('email_member_form');

        // Get events to build Event filter dropbox.
        $.ajax("events")
        	.done(function(data) {
          		var i;
                var receiptDate;
            	for (i = 0; i < data.length; i++) {
                	$("#select_events").append('<option value=' + data[i].id + '>' + data[i].name + '</option>\n');
                    receiptDate = AppiUtils.toDate(data[i].end_date);
                    if (receiptDate) {
                        receiptDate.setFullYear(receiptDate.getFullYear() + 2);
                        if (receiptDate > Date.now()) {
                            $("#email_payables").append('<option value=' + data[i].id + '>' + data[i].name + '</option>\n');
                        }
                    }
            	}
                AppiFilters.loadSelectPickerFromStorage('select_events');

            	$('#select_events').selectpicker('refresh');
            	$('#email_payables').selectpicker('refresh');
          	})
          	.fail(function() {
                bootbox.dialog({
                        message: "Erro a obter eventos.",
                        title:   "Erro no acesso à BD",
                        icon:    "fa-exclamation text-danger",
                        buttons: { OK: { } }
                });
          	});

        // Get fees to build Fee filter dropbox.
        $.ajax("fees")
        	.done(function(data) {
          		var i;
                var receiptDate;
            	for (i = 0; i < data.length; i++) {
                	$("#select_fees").append('<option value=' + data[i].id + '>' + data[i].name + '</option>\n');
                    receiptDate = AppiUtils.toDate(data[i].due_date);
                    if (receiptDate) {
                        receiptDate.setFullYear(receiptDate.getFullYear() + 2);
                        if (receiptDate > Date.now()) {
                            $("#email_payables").append('<option value=' + data[i].id + '>' + data[i].name + '</option>\n');
                        }
                    }
            	}
                AppiFilters.loadSelectPickerFromStorage('select_fees');

            	$('#select_fees').selectpicker('refresh');
            	$('#email_payables').selectpicker('refresh');
          	})
          	.fail(function() {
                bootbox.dialog({
                        message: "Erro a obter quotas.",
                        title:   "Erro no acesso à BD",
                        icon:    "fa-exclamation text-danger",
                        buttons: { OK: { } }
                });
          	});

        $.fn.dataTable.moment('DD/MM/YYYY');

        // Create datatables table.
    	dtTable = $(dtTableId).on('preXhr.dt', function ( e, settings, data ) {
                    $(dtTableId).css({ opacity: 0.5 });
                    $('#spinner').show();
              }).on( 'xhr.dt', function () {
                    $('#spinner').hide();
                    $(dtTableId).css({ opacity: 1 });
              })
              .DataTable( {
                //dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'copy',
                        text: 'Copiar',
                        exportOptions: { columns: ':visible' } ,
                        titleAttr: "Copiar todos os dados na tabela para o clipboard"
                    },
                    {
                        extend: 'excel',
                        text: 'Excel',
                        exportOptions: { columns: ':visible' },
                        titleAttr: "Exportar todos os dados na tabela para Excel"
                    },
                    {
                        extend: 'pdf',
                        text: 'Pdf',
                        exportOptions: { columns: ':visible' },
                        orientation: 'landscape',
                        titleAttr: "Exportar todos os dados na tabela para PDF"
                    },
                    {
                        extend: 'print',
                        text: 'Imprimir',
                        exportOptions: { columns: ':visible' },
                        orientation: 'landscape',
                        titleAttr: "Imprimir todos os dados na tabela"
                    },
                    {
                        text: "Etiquetas",
                        action: BuildLabelsPdf,
                        titleAttr: "Gerar etiquetas para associados selecionados"
                    },
                ],
                select: {
                    style: 'os' // Was 'single'
                },
                "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "Tudo"]],
                "autoWidth": true,
                //stateSave: true, // Can't be used with 'responsive'
                responsive: true,  // Can't be used with 'stateSave'
                "ajax": {
                    "url": "members",
                    "data": function () {
                                var payables_op = { 'payables_op': $("input[name=payables_logical_op]:checked").val() || [] };
                                var object1 = { 'events': $('select[name="events[]"]').val() || [] };
                                var object2 = { 'fees': $('select[name="fees[]"]').val() || []};
                                return $.extend(object1, object2, payables_op);
                    },
                    "dataSrc": ""
                },
                "deferRender": true,
                "initComplete": function(settings, json) {
                    // Add buttons to page.
                    dtTable.buttons().container().appendTo('#button_container');
                },
                "aaSorting": [], // Disable initial sort
                "columns": [
                    { "title": "Id", "data": "id", "visible": false, "className": "none never" },
                    { "title": "Número", "data": "number" },
                    { "title": "Nome", "data": "full_name" },
                    { "title": "Apelido", "data": "last_name" },
                    { "title": "Morada", "data": "address" },
                    { "title": "Código Postal", "data": "zip_code" },
                    { "title": "Localidade", "data": "place" },
                    { "title": "Distrito", "data": "district" },
                    { "title": "País", "data": "country" },
                    { "title": "Tel. Fixo", "data": "fixed_phone" },
                    { "title": "Telemóvel", "data": "mobile_phone" },
                    { "title": "Outro Tel.", "data": "other_phone" },
                    { "title": "Est. Ensino", "data": "edu_institution" },
                    { "title": "Nível Ensino", "data": "edu_level" },
                    { "title": "BI/CC", "data": "ncc" },
                    { "title": "NIF", "data": "nif" },
                    { "title": "Newsletter", "data": "newsletter" },
                    { "title": "Data Nasc.", "data": "birth_date" },
                    { "title": "Ano Admissão", "data": "admission_year" },
                    { "title": "Email", "data": "email" },
                    { "title": "Ativo", "data": "active" },
                    { "title": "Instituição", "data": "institution" },
                    { "title": "Obs.", "data": "observations" }
                ],
                "columnDefs": [
                    { "targets": [ 0 ], "visible": false, "searchable": false },
                    { "targets": [ 1 ], type: "unnatural" }
                ],
                "language": {
                  "url": "//cdn.datatables.net/plug-ins/1.10.7/i18n/Portuguese.json",
                  "decimal": ",",
                  "thousands": ".",
                  select: {
                    rows: {
                        _: "Selecionou %d linhas",
                        0: "Clique numa linha para selecionar",
                        1: "1 linha selecionada"
                    }
                  }
                }
        } );

        if (dtTable) {

            $('#country').on('change', function(e) {
                $('#edit_member_form').validate().element('#zip_code');
                $('#edit_member_form').validate().element('#nif');
            });

            // Deselect all if sorted, page changed, or table filtered
            dtTable.on('order.dt page.dt search.dt', function () {
                dtTable.rows().deselect();
            } );

            // ///////////////////////////////////////////////////////////////////////////////////////
            // Table select/deselect handlers
            dtTable.on('select', function(e, dt, type, indexes) {
                if (type === 'row')
                    if (dtTable.rows('.selected').count() == 1) {
                        var selected_member_id = dtTable.rows(indexes).data().pluck('id')[0];
                        $("#delete_btn").closest("form").attr("action", "/members/" + selected_member_id);
                        $('#edit_btn, #delete_btn, #properties_btn').toggleClass('disabled', false).disabled = false;
                    }
                    else {
                        $("#delete_btn").closest("form").attr("action", "/members/" + 0);
                        $('#edit_btn, #delete_btn, #properties_btn').toggleClass('disabled', true).disabled = true;
                    }
            } );
            dtTable.on('deselect', function (e, dt, type, indexes) {
                if (type === 'row')
                    if (dtTable.rows('.selected').count() == 1) {
                        var selected_member_id = dtTable.rows(indexes).data().pluck('id')[0];
                        $("#delete_btn").closest("form").attr("action", "/members/" + selected_member_id);
                        $('#edit_btn, #delete_btn, #properties_btn').toggleClass('disabled', false).disabled = false;
                    }
                    else {
                        $("#delete_btn").closest("form").attr("action", "/members/" + 0);
                        $('#edit_btn, #delete_btn, #properties_btn').toggleClass('disabled', true).disabled = true;
                    }
            } );

            // Clean edit modal when it is hidden
            $("#editMemberModal").on('hidden.bs.modal', function () {
                $('.form-group').removeClass('has-error has-feedback');
                $('.form-group').find(".list-unstyled").remove();
                $('.form-group').find('small.help-block').hide();
                $('.form-group').find('i.form-control-feedback').hide();
                $(document).off( "keypress" );
            });

            // Here we clean any errors that came from server validation.
            $("#editMemberModal input").on('focusout', function () {
                var form_group = $(this).closest('.form-group');
                var block = form_group.find('.help-block').not($("[id$=-error]"));
                if (block.text().length > 2) {
                    form_group.removeClass('has-error has-feedback');
                    block.find(".list-unstyled").remove();
                }
            });

            // Create/Edit form submission handler using AJAX.
            $("form#edit_member_form button#submit").click(this.editFormSubmitHandler);

            // Delete form submission handler using AJAX.
            $("form#delete_member_form button#submit").click(this.deleteFormSubmitHandler);

	        // Email form submission handler using AJAX.
	        $("form#email_member_form button#submit").click(this.emailFormSubmitHandler);

            $('#editMemberModal').on('shown.bs.modal', function (event) {
                var action = $('#edit_member_form').closest('.modal').data('action');

                $(document).keydown(function( event ) {
                    var key = event.which || event.keyCode || 0;
                    if ( key == parseInt(APPIconfig['zip_code_key']) ) {
                        // Use configured key to fill CP data
                        event.preventDefault();
                        if ($('#zip_code').val().length == 4 && $('#place').val().length == 0) {
                            bootbox.dialog({
                                    message: "Sem o código postal completo, a localidade não poderá ser obtida com exatidão." +
                                            "<br><br>" +
                                            "Será fornecia a primeira localidade por ordem alfabética.",
                                    title:   "Código Postal Incompleto",
                                    icon:    "fa-exclamation text-danger",
                                    buttons: { OK: { } }
                            });
                        }
                        AppiUtils.ajaxCheckZipCode({zip: $('#zip_code').val()});
                    }
                });

                if (action == "edit") {
                    // Force validation
                    mef_validator.form();
                }
                else if (action == "create") {
                    mef_validator.resetForm();
                }
            });

            // Populate delete member modal window before it is shown
            $('#deleteMemberModal').on('show.bs.modal', this.OnShowDeleteModal);

            $('#emailMemberModal').on('show.bs.modal', this.OnShowEmailModal);

            // Populate create/edit member modal window before it is shown.
            $('#editMemberModal').on('show.bs.modal', this.OnShowEditModal);

            $('#memberPropertiesModal').on('show.bs.modal', this.OnShowMemberPropertiesModal);

	        /////////////////////////////////////////////////////////////////////////////
	        // When any of the select controls become hidden, reload table data.
	        $('#select_events, #select_fees').on('hidden.bs.select', function () {
	            // Reload Member table
	            dtTable.ajax.reload();
	        } );

            $("input[name=payables_logical_op]").change(function () {
	            // Reload Member table
	            dtTable.ajax.reload();
	        });

            // Filters
            AppiFilters.initDatatablesFilters(dtTable);
        }

        return dtTable;
    }

    // Return the public part of this object
	return pub;
}());